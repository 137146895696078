import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_TAREAS_PROG_ANUAL = `
        query get($filtro: InputGetTareasProgAnual){
            getTareasProgAnual(filtro: $filtro){
            tareaID
            programaAnualID
            empresaID
            polizaID
            ramoSeguroID
            usuarioID
            usuarioBitID
            numero
            tipoTarea
            objetivo
            fechaAlta
            fechaModificacion
            estado
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            extraordinaria
            siglas
            numPolEndoso
            ramoSeguro
            numPoliza
            }
        }`;

export const GET_ALL_TAREAS_PROG_ANUAL = `
        query filtroGetAll($filtro: InputGetTareasProgAnual){
            getAllTareasProgAnual(filtro: $filtro){
            tareaID
            programaAnualID
            empresaID
            polizaID
            ramoSeguroID
            usuarioID
            usuarioBitID
            numero
            tipoTarea
            objetivo
            fechaAlta
            fechaModificacion
            estado
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            extraordinaria
            siglas
            numPolEndoso
            ramoSeguro
            numPoliza
            }
        }`;

export const TABLA_TAREAS_PROG_ANUAL = `
        query filtro($filtro: InputFiltroTareasProgAnual){
            filterTareasProgAnual(filtro: $filtro){
            listado{
            tareaID
            programaAnualID
            empresaID
            polizaID
            ramoSeguroID
            usuarioID
            numero
            tipoTarea
            objetivo
            fechaAlta
            fechaModificacion
            estado
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            extraordinaria
            siglas
            numPolEndoso
            ramoSeguro
            filtroMes
            folioPoliza
            vigenciaIniPoliza
            vigenciaHastaPoliza
            numPoliza
            }
            registros
            paginas
            }
        }`;

export const getTareasProgAnual = async (filtro, token) => {
    try {
        let { getTareasProgAnual } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_TAREAS_PROG_ANUAL
        });

        return getTareasProgAnual;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllTareasProgAnual = async (variables, token) => {
    try {

        let { getAllTareasProgAnual } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_TAREAS_PROG_ANUAL
        });

        return getAllTareasProgAnual;

    } catch ({ message }) { throw new Error(message); }
}

export const seleccionTareasProgAnual = async (variables, token) => {
    try {

        let allTareasProgAnual = await getAllTareasProgAnual(variables, token);
        if (!Array.isArray(allTareasProgAnual)) throw new Error("No se encontraron registros");

        return allTareasProgAnual.map(({ tareaID: value, tipoTarea, objetivo: label }) => ({ value, label: `${tipoTarea.toUpperCase()} - ${label.toUpperCase()}` }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaTareasProgAnual = async (variables, token) => {
    try {
        let { filterTareasProgAnual } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_TAREAS_PROG_ANUAL
        });
        return filterTareasProgAnual;
    } catch ({ message }) { throw new Error(message); }
}
